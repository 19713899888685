import fetchAPI from "./fetchAPI"

export async function searchContent(
  string,
  context,
  filters,
  view = "grid",
  token = null
) {
  const headers = token ? { Authorization: `Bearer ${token}` } : null

  const { searchContent } = await fetchAPI(
    `query($string: String, $context: searchContext!, $filters: JSON!, $view: searchView) {
        searchContent(string: $string, context: $context, filters: $filters, view: $view) {
          result
          totalCount
          maxPage
          cardType
          hash
        }
      }`,
    {
      variables: { string, context, filters, view }
    },
    headers
  )

  return searchContent
}

export async function tickSearchClick(token, hash) {
  if (!hash) {
    return null
  }
  const data = await fetchAPI(
    `mutation($token: String!, $hash: String!) {
        tickSearchClick(token: $token, hash: $hash) {
          status
        }
      }`,
    {
      variables: { token, hash }
    },
    true
  )

  return data.tickSearchClick
}
